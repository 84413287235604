<template>
  <v-card flat class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
    >
      <v-icon> {{ ui.snackbar.icon }}</v-icon>
      {{ ui.snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs"> mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->

    <!--  EDIT PASSWORD   -->
    <v-row>
      <v-card-title>{{ $t('user.profile.info.title') }}</v-card-title>
    </v-row>
    <v-card-text>
      {{ $t('user.profile.info.subtitle') }}
    </v-card-text>
    <!--  DIALOG EDIT PASSWORD   -->
    <v-card-text class="pt-0">
      <v-card-actions class="pl-0">
        <v-dialog v-model="dialogInfo" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on" color="primary" @click="[userEdit = {...userInfo}]">
              {{ $t('user.profile.info.action_button.edit') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="primary white--text justify-space-between">
              {{ $t('user.profile.info.edit.title') }}
              <v-icon color="white" @click="dialogInfo = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5">
              <v-overlay :value="loading" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-alert
                  v-model="ui.alert.enable"
                  :dismissible="ui.alert.dismissible"
                  :icon="ui.alert.icon"
                  :type="ui.alert.type"
                  class="mb-6 mt-1"
                  dense
              >
                {{ ui.alert.message }}
              </v-alert>
              <v-form ref="form" v-model="valid" v-on:submit.prevent="editUser">
                <v-card-text class="px-0">
                  <v-row>
                    <v-card-text class="py-0">{{ $t('user.profile.info.edit.form.general') }}</v-card-text>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field :label="this.$t('user.profile.info.edit.form.data.email')" v-model="userEdit.email"
                                    outlined dense
                                    hide-details disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field :label="this.$t('user.profile.info.edit.form.data.nickname')"
                                    v-model="userEdit.nickname" outlined dense
                                    :rules="[rules.nickname_length]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-2">
                    <v-card-text class="py-0">{{ $t('user.profile.info.edit.form.password') }}</v-card-text>
                    <v-col cols="12" sm="12" md="12" class="pb-0">
                      <v-text-field :label="this.$t('user.profile.info.edit.form.data.password')" v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="[rules.password_length, rules.password_letter, rules.password_number, rules.match, rules.required_password]"
                                    outlined dense
                                    @click:append="showPassword = !showPassword"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <v-text-field :label="this.$t('user.profile.info.edit.form.data.password_confirm')"
                                    v-model="password_confirm"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="[rules.required_password, rules.match]"
                                    ref="password_confirm"
                                    outlined dense
                                    @click:append="showPassword = !showPassword"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="editUser">{{ $t('user.profile.info.edit.button.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card-text>

    <v-divider class="mt-3 mb-3 primary"></v-divider>

    <!-- IMAGE AVATAR EDIT   -->
    <v-row>
      <v-card-title>
        {{ $t('user.profile.avatar.title') }}
      </v-card-title>
    </v-row>
    <v-card-text>
      {{ $t('user.profile.avatar.subtitle') }}
    </v-card-text>

    <!--  AVATAR IMAGE   -->
    <v-list-item>
      <v-list-item-avatar size="150" class="avatarColor"
      >
        <v-img :src="displayAvatar">
          <v-overlay :value="loadingAvatar" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-img>
      </v-list-item-avatar>

      <!--  DIALOG AVATAR IMAGE   -->
      <v-dialog v-model="dialogAvatar" width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on" color="primary">{{ $t('user.profile.avatar.action_button') }}</v-btn>
        </template>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('user.profile.avatar.form.title') }}
            <v-icon color="white" @click="dialogAvatar = false">mdi-close</v-icon>
          </v-card-title>
          <v-spacer></v-spacer>

          <!-- Upload by choosing image or drag n drop         -->
          <v-card-text v-if="!previewAvatar" class="pt-5">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1"
            >
              {{ ui.alert.message }}
            </v-alert>
            <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragEnter"
                 @dragleave="dragging = false">
              <div class="dropzone-wrapper">
                <div class="dropzone-info text-center">
                  <v-icon color="primary">mdi-upload</v-icon>
                </div>
                <div class="dropzone-info text-center" @drag="onChangeFileUpload($event, 'avatar')">
                  <span class="dropzone-title">{{ $t('user.profile.avatar.form.image_upload') }}</span>
                </div>
              </div>
              <input type="file" @change="onChangeFileUpload($event, 'avatar')" accept="image/*">
            </div>
          </v-card-text>
          <!-- End of upload by choosing image or drag n drop  -->

          <div v-if="previewAvatar">
            <v-overlay :value="loading_image" absolute>
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-text class="pt-5">
              <cropper ref="cropper" class="cropper"
                       :src="previewAvatar"
                       :transitions="true"
                       image-restriction="fit-area"
                       :canvas="{
                              width: 200,
                              height: 200,
                              minWidth: 200,
                              minHeight: 200,
                              maxWidth: 4096,
                              maxHeight: 4096,
                              imageSmoothingEnabled: true,
                              imageSmoothingQuality: 'high',
                            }"
                       :stencil-props="{
                         previewClass: 'preview',
                         aspectRatio: 1,
                         cropBoxResizable: false,
                         minCropBoxWidth: 200,
                         minCropBoxHeight: 200,
                         }"
                       stencil-component="circle-stencil" :debounce="false"
                       @change="onChange"
                       @ready="ready"
                       @error="onError" />

            </v-card-text>
            <!--  CROPPER ACTION BUTTONS          -->
            <v-card-text class="text-center buttons">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="rotate(90)">
                    <v-icon>mdi-rotate-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.rotate_left') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="rotate(-90)">
                    <v-icon>mdi-rotate-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.rotate_right') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="flip(true, false)">
                    <v-icon>mdi-flip-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.flip_horizontally') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="flip(false, true)">
                    <v-icon>mdi-flip-vertical</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.flip_vertically') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="zoom(2)">
                    <v-icon>mdi-magnify-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.zoom_in') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="zoom(0.5)">
                    <v-icon>mdi-magnify-minus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.zoom_out') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="maximize()">
                    <v-icon>mdi-arrow-expand-all</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.maximize') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="center()">
                    <v-icon>mdi-image-filter-center-focus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.avatar.form.cropper.actions.focus') }}</span>
              </v-tooltip>
            </v-card-text>
            <!--  END OF CROPPER ACTION BUTTONS          -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="save('avatar')">
                {{ $t('user.profile.avatar.form.button.save') }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
      <cropper ref="cupdate" class="cropper" style="visibility: hidden; width: 200px; height: 200px"
               :src="displayAvatar"
               :transitions="true"
               image-restriction="fit-area"
               :canvas="{
                              width: 200,
                              height: 200,
                              minWidth: 200,
                              minHeight: 200,
                              maxWidth: 4096,
                              maxHeight: 4096,
                              imageSmoothingEnabled: true,
                              imageSmoothingQuality: 'high',
                            }"
               :stencil-props="{
                         previewClass: 'preview',
                         aspectRatio: 500/500,
                         cropBoxResizable: false,
                         minCropBoxWidth: 200,
                         minCropBoxHeight: 200,
                         }"
               :stencil-size="{
		width: 200,
		height: 200
	}"
               stencil-component="circle-stencil" :debounce="false"
               @change="onChange"
               @ready="ready"
               @error="onError"
      />
    </v-list-item>

    <!--  AVATAR BORDER COLOR   -->
    <v-card-text>
      {{ $t('user.profile.avatar_border_color.title') }}
      <v-dialog v-model="dialogPicker" width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-5" :style="'background-color: ' + userInfo.color + ' !important;'" outlined text plain
                 v-bind="attrs" v-on="on"></v-btn>
        </template>
        <v-card>
          <v-overlay :value="loading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-color-picker class="texte-center" v-model="picker" mode="hex" hide-mode-switch></v-color-picker>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="saveColor">{{
                $t('user.profile.avatar_border_color.button.save')
                                                           }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>


    <v-divider class="mt-3 mb-3 primary"></v-divider>

    <!-- IMAGE WEBCAM  -->
    <v-row>
      <v-card-title>
        {{ $t('user.profile.webcam.title') }}
      </v-card-title>
    </v-row>
    <v-card-text>
      {{ $t('user.profile.webcam.subtitle') }}
    </v-card-text>
    <v-list-item>
      <v-list-item-avatar tile style="max-height: 360px; max-width: 640px; height: fit-content; width: fit-content"
                          class="imgUser">
        <v-img :src="displayWebcam">
          <v-overlay :value="loadingWebcam" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-img>
      </v-list-item-avatar>
      <!--  DIALOG WEBCAM IMAGE   -->
      <v-dialog v-model="dialogWebcam" width="600" class="dialog-webcam">
        <v-overlay :value="loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on" color="primary">{{ $t('user.profile.webcam.action_button') }}</v-btn>
        </template>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('user.profile.webcam.form.title') }}
            <v-icon color="white" @click="dialogWebcam = false">mdi-close</v-icon>
          </v-card-title>
          <v-spacer></v-spacer>

          <!-- Upload by choosing image or drag n drop         -->
          <v-card-text v-if="!previewImage" class="pt-5">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1"
            >
              {{ ui.alert.message }}
            </v-alert>
            <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragEnter"
                 @dragleave="dragging = false">
              <div class="dropzone-wrapper">
                <div class="dropzone-info text-center">
                  <v-icon color="primary">mdi-upload</v-icon>
                </div>
                <div class="dropzone-info text-center" @drag="onChangeFileUpload($event, 'webcam')">
                  <span class="dropzone-title">{{ $t('user.profile.webcam.form.image_upload') }}</span>
                </div>
              </div>
              <input type="file" @change="onChangeFileUpload($event, 'webcam')" accept="image/*">
            </div>
          </v-card-text>
          <!-- End of upload by choosing image or drag n drop  -->

          <div v-if="previewImage">
            <v-overlay :value="loading_image" absolute>
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-text class="pt-5">
              <cropper ref="cropper" class="cropper" :src="previewImage"
                       :stencil-props="{
                              handlers: {},
                              movable: false,
                              resizable: false
                             }"
                       :canvas="{
                                maxHeight: 360,
                                minWidth: 640
                              }"
                       :stencil-size="{
                                width: 640,
                                height: 360
                              }"
                       image-restriction="stencil"
                       @change="onChange"
                       @ready="ready"
                       @error="onError" />
            </v-card-text>
            <!--  CROPPER ACTION BUTTONS          -->
            <v-card-text class="text-center buttons">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="rotate(90)">
                    <v-icon>mdi-rotate-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.rotate_left') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="rotate(-90)">
                    <v-icon>mdi-rotate-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.rotate_right') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="flip(true, false)">
                    <v-icon>mdi-flip-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.flip_horizontally') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="flip(false, true)">
                    <v-icon>mdi-flip-vertical</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.flip_vertically') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="zoom(2)">
                    <v-icon>mdi-magnify-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.zoom_in') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="zoom(0.5)">
                    <v-icon>mdi-magnify-minus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.zoom_out') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="maximize()">
                    <v-icon>mdi-arrow-expand-all</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.maximize') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="center()">
                    <v-icon>mdi-image-filter-center-focus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('user.profile.webcam.form.cropper.actions.focus') }}</span>
              </v-tooltip>
            </v-card-text>
            <!-- END CROPPER ACTION BUTTONS          -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="save('webcam')">
                {{ $t('user.profile.webcam.form.button.save') }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </v-list-item>

  </v-card>
</template>
<script>

import 'vue-advanced-cropper/dist/style.css'
import _ from "@/tools/lodash";

export default {
  name: "Profile",
  components: {},
  data() {
    return {
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        },
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1
        }
      },
      loading_image: false,
      userInfo: {}, // init object with user info

      // dialog user edit
      dialogInfo: false, // open or close edit user info dialog
      userEdit: {}, // editable info about the user
      password: '',
      password_confirm: '',
      showPassword: false,
      valid: true, // form valid
      rules: {
        required: value => !!value || this.$t('user.profile.info.edit.form.rules.required'),
        required_password: value => {
          if (this.password_confirm) {
            return value.length > 0 || this.$t('user.profile.info.edit.form.rules.required')
          } else return true
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        nickname_length: value => (!!value && value.length >= 2 && value.length <= 16) || this.$t('user.profile.info.edit.form.rules.nickname_length'),
        match: value => {
          if (this.password) {
            return value === this.password || this.$t('user.profile.info.edit.form.rules.no_match')
          } else return true
        },
        password_length: value => (value.length >= 8 || value.length === 0) || this.$t('user.profile.info.edit.form.rules.password_length'),
        password_number: value => {
          if (value) {
            const pattern = /\d/
            return pattern.test(value) || this.$t('user.profile.info.edit.form.rules.password_number')
          } else return true
        },
        password_letter: value => {
          if (value) {
            const pattern = /[a-zA-Z]/
            return pattern.test(value) || this.$t('user.profile.info.edit.form.rules.password_letter')
          } else return true
        },
      },

      // avatar border color
      color: null,
      picker: null,

      loading: false,
      loadingWebcam: false,
      loadingAvatar: false,
      dialogAvatar: false,
      dialogPicker: false,
      dialogWebcam: false,

      displayAvatar: '',
      display_avatar_default: "https://via.placeholder.com/640x360",
      displayWebcam: '',
      currentAvatar: undefined,
      previewAvatar: undefined,
      currentImage: undefined,
      previewImage: undefined,
      size: {
        width: null,
        height: null,
      },
      result: {
        coordinates: null,
        image: null
      },
      dragging: false,
    }
  },
  watch: {
    dialogInfo: function () {
      if (this.dialogInfo === false) {
        this.userEdit = {}
        this.ui.alert.enable = false
        this.password = ''
        this.password_confirm = ''
        this.$refs.form.resetValidation()
      }
    },
    dialogWebcam: function () {
      if (this.dialogWebcam === false) {
        this.previewImage = null
        this.ui.alert.enable = false
      }
    },
    dialogAvatar: function () {
      if (this.dialogAvatar === false) {
        this.previewAvatar = null
        this.ui.alert.enable = false
      }
    },
    password: function () {
      this.$refs.password_confirm.validate()
    }
  },
  methods: {
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    snackbar(type, icon, message, timeout) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
    },
    /**
     * Return image from cropper as a circle and with border
     *  @param type string Type of action : initial upload or image update
     *  If type === upload => canvas from dialog cropper is used
     *  If type === update => hidden canvas is used
     *
     * */
    getRoundedCanvas(type) {
      let cropper_type = null

      // if image needs to be re-uploaded, take image that is in the hidden cropper
      if (type === 'update')
        cropper_type = this.$refs.cupdate
      // if it's the first upload, take image from dialog cropper
      if (type === 'upload')
        cropper_type = this.$refs.cropper

      let {canvas} = cropper_type.getResult();

      let context = canvas.getContext('2d');

      context.globalCompositeOperation = 'destination-in';

      context.beginPath();
      context.fillStyle = 'black'
      context.arc(200 / 2, 200 / 2, 200 / 2, 0, 2 * Math.PI, true);
      context.fill();
      context.closePath()
      context.clip()

      // set avatar border color
      if (this.userInfo.color) {
        context.beginPath()
        context.globalCompositeOperation = 'source-over'
        context.arc(200 / 2, 200 / 2, 200 / 2, 0, 2 * Math.PI, true);

        context.strokeStyle = this.userInfo.color
        context.lineWidth = 10
        context.stroke()
      }

      return canvas
    },
    /**
     * Get user info
     * */
    async getUserInfos() {
      this.loading = true

      try {
        const response = (await this.axios.post('user/info', {
          auth_token: this.$session.get('token'),
          user_key: this.$session.get('key')
        })).data

        this.userInfo = {
          'nickname': !_.isUndefined(response.nickname) ? response.nickname : null,
          'login': !_.isUndefined(response.login) ? response.login : null,
          'email': !_.isUndefined(response.email) ? response.email : null,
          'avatar': !_.isUndefined(response.avatar_code) ? response.avatar_code : null,
          'color': !_.isUndefined(response.color_code) ? response.color_code : null,
          'webcam': !_.isUndefined(response.webcam_code) ? response.webcam_code : null,
        }

        this.displayAvatar = !_.isEmpty(this.userInfo.avatar) ? process.env.VUE_APP_API_ROHAN_PATH + this.userInfo.avatar : 'https://via.placeholder.com/640x360'
        this.displayWebcam = !_.isEmpty(this.userInfo.webcam) ? process.env.VUE_APP_API_ROHAN_PATH + this.userInfo.webcam : 'https://via.placeholder.com/640x360'

      } catch (e) {
        if (e.response?.status !== undefined) {
          switch (e.response.status) {

              // Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.get_info.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not exist
            case 404:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.get_info.error.invalid_method'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.get_info.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Event that fires when image detected on drag n drop zone or user uploads a picture
     *
     * Loads File image into cropper
     *
     * */
    onChangeFileUpload(e, type) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files[0].type.includes('image')) {
        this.dragging = false
        this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.avatar.form.cropper.error.not_authorized'), true)
        return
      }

      if (!files.length) {
        this.dragging = false;
        this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.avatar.form.cropper.error.load_error'), true)
        return;
      }

      if (files[0].size >= 2500000) {
        this.dragging = false;
        this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.avatar.form.cropper.error.file_size'), true)
        return;
      }

      this.selectImage(files[0], type);
    },
    /**
     * Event on drag enter on file upload in dialog
     * */
    dragEnter() {
      this.ui.alert.enable = false
      this.dragging = true
    },
    /**
     * Creates Object URL from cropped picture
     * */
    selectImage(image, type) {
      this.loading_image = true

      if (type === 'webcam') {
        this.currentImage = image;
        this.previewImage = URL.createObjectURL(this.currentImage);
      }

      if (type === 'avatar') {
        this.currentAvatar = image;
        this.previewAvatar = URL.createObjectURL(this.currentAvatar);
      }

      this.dragging = false;
    },
    /**
     * On cropper image change
     * */
    onChange({coordinates, image}) {
      this.result = {
        coordinates,
        image
      };
    },
    /**
     * Event fired when cropper has finished loading image
     * */
    ready() {
      this.loading_image = false
    },
    /**
     * Event fired when cropper cannot load image
     * */
    onError() {
      this.loading_image = false
      this.previewAvatar = null
      this.previewImage = null

      this.alert('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar.form.cropper.error.load_error'), true)
    },
    /**
     * Save image to db
     *
     * @param type string Type of image saved, it can be 'avatar' or 'webcam'
     * @param action
     * */
    async save(type, action = 'upload') {
      this.loading_image = true

      try {
        let canvas = undefined
        if (type === 'webcam')
          canvas = this.$refs.cropper.getResult().canvas;

        if (type === 'avatar')
          canvas = this.getRoundedCanvas(action)

        if (canvas) {
          const form = new FormData();
          form.append('auth_token', this.$session.get('token'))
          form.append('action', type)

          canvas.toBlob(blob => {
            form.append('cropped_image', blob);
            this.axios.post('user/upload/image', form,
                {
                  headers: {'Content-Type': 'multipart/form-data'}
                }).then(async response => {
              if (response.status === 200) {

                await this.getUserInfos().then(() => {

                  if (type === 'avatar') {
                    this.$root.$emit('avatarChanged', this.userInfo.avatar)
                    this.loadingAvatar = false
                    this.dialogAvatar = false
                  }

                  if (type === 'webcam') {
                    this.loadingWebcam = false
                    this.loading_image = false
                    this.dialogWebcam = false
                  }

                  this.previewAvatar = undefined
                  this.previewImage = undefined
                  this.currentAvatar = undefined
                  this.currentImage = undefined

                  this.snackbar('success', 'mdi-check-circle-outline', this.$t('user.profile.avatar.form.success_message'), '4000')
                })
              }
            }).catch(e => {
              if (e.response?.status !== undefined) {
                switch (e.response.status) {
                    // Invalid params
                  case 400:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar.form.error.invalid_params'))
                    break

                    // Invalid token
                  case 401:
                    this.$session.destroy()
                    this.$router.push({name: "Home"})
                    break

                    // Invalid image type or action type
                  case 403:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar.form.error.invalid_image'))
                    break

                    // Wrong method
                  case 405:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar.form.error.invalid_method'))
                    break

                  default:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar.form.error.unknown'))
                }
              }
            }).finally(() => {
              this.dialogAvatar = false
              this.dialogWebcam = false
              this.loading_image = false
            })
          }, 'image/png');
        }
      } catch (e) {
        console.log('hereeeeee');
        console.log(e);
      }
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    maximize() {
      const {cropper} = this.$refs;
      const center = {
        left: cropper.coordinates.left + cropper.coordinates.width / 2,
        top: cropper.coordinates.top + cropper.coordinates.height / 2,
      };
      cropper.setCoordinates([
        ({imageSize}) => ({
          width: imageSize.width,
          height: imageSize.height,
        }),
        ({coordinates}) => ({
          left: center.left - coordinates.width / 2,
          top: center.top - coordinates.height / 2,
        }),
      ]);
    },
    center() {
      this.$refs.cropper.setCoordinates(({coordinates, imageSize}) => ({
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
      }));
    },
    /**
     * Save avatar border color
     *
     * On each avatar border color update, the image's border color is updated and re-uploaded
     *
     * */
    async saveColor() {
      this.loading = true

      try {
        await this.axios.post('user/avatar/color', {
          auth_token: this.$session.get('token'),
          color_avatar: 'rgba(' + this.picker.rgba.r + ', ' + this.picker.rgba.g + ', ' + this.picker.rgba.b + ', ' + (this.picker.rgba.a).toFixed(2) + ')'
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('user.profile.avatar_border_color.success_message'), '4000')


        await this.getUserInfos().then(() => {
          this.save('avatar', 'update')

          // emit event to update border color (listener : TopBar)
          this.$root.$emit('colorChanged', this.userInfo.color)
          this.dialogPicker = false
        })

      } catch (e) {
        if (e.response?.status !== undefined) {
          switch (e.response.status) {

              // Invalid params
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar_border_color.error.invalid_params'))
              break

              // Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // Invalid method
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar_border_color.error.invalid_method'))
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('user.profile.avatar_border_color.error.unknown'))
          }
        }
      } finally {
        this.dialogPicker = false
        this.loading = false
      }
    },
    /**
     * Edit user infos
     * @returns {Promise<void>}
     */
    async editUser() {
      if (this.$refs.form.validate()) {

        this.loading = true

        try {
          await this.axios.post('user/edit', {
            auth_token: this.$session.get('token'),
            user_key: this.$session.get('key'),
            nickname: this.userEdit.nickname,
            password: this.password,
            email: this.userEdit.email,
            login: this.userEdit.email
          })

          this.snackbar('success', 'mdi-check-circle-outline', this.$t('user.profile.info.edit.success_message'), '4000')
          this.dialogInfo = false

          await this.getUserInfos()

          this.userEdit = {}
          this.password = ''
          this.password_confirm = ''
        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {
                // Email is not available
              case 300:
                this.alert('error', 'mdi-alert-octagon', 'Email is already taken, please choose another email.', true)
                break

                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.info.edit.error.invalid_params'), true)
                break

                // Invalid token
              case 401:
                this.alert('error', 'mdi-alert-octagon', 'Vous n\'êtes pas autorisé.', true)
                break

                // Password min size 8 with digit and alpha
              case 403:
                this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.info.edit.error.password_not_match'), true)
                break

                // User does not exist
              case 404:
                this.alert('error', 'mdi-alert-octagon', 'Vous n\'êtes pas autorisé.', true)
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.info.edit.error.invalid_method'), true)
                break

              default:
                this.alert('error', 'mdi-alert-octagon', this.$t('user.profile.info.edit.error.unknown'), true)
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  async updated() {
    if (!this.dialogAvatar) {
      this.previewAvatar = undefined
    }

    if (!this.dialogWebcam) {
      this.previewImage = undefined
    }
  },
  async mounted() {
    await this.getUserInfos()
  },
  beforeDestroy() {
    this.$root.$off('avatarChanged')
    this.$root.$off('colorChanged')
  },
  destroyed() {
    if (this.previewAvatar)
      URL.revokeObjectURL(this.previewAvatar)

    if (this.previewImage)
      URL.revokeObjectURL(this.previewImage)
  }

}
</script>

<style lang="scss" scoped>

.dropzone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #91b0b3;
}

.dropzone:hover {
  border: 2px dashed #790346;
}

.dropzone:hover .dropzone-info {
  color: #790346 !important;
}

.dropzone-wrapper {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
}

.dropzone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropzone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

::v-deep {
  .v-progress-circular {
    margin: 1rem;
    color: RGBA(121, 7, 72, 1);
  }

  .v-dialog {
    overflow-y: hidden !important;
  }

  .buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }


  .vue-advanced-cropper__foreground, .vue-advanced-cropper__background, .vue-advanced-cropper {
    max-height: 500px !important;
  }

  .v-color-picker__hue {
    margin-bottom: 0px!important;
  }

  .v-color-picker__alpha {
   display:none!important;
  }

}

.profileContent {
  margin: 0 auto;
  width: 90%;
  height: 100%;

  ::v-deep {
    .v-list-item {
      padding: 0;
    }


    .imgUser {
      border: RGBA(121, 7, 72, 1) 2px solid;
    }


    .webCamContent, .avatarContent, .infosContent {
      margin-top: 2%;
      margin-bottom: 5%;
    }

  }

}
</style>
